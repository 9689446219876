import { useMutation } from '@tanstack/react-query'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getDatabase, push, ref, serverTimestamp } from 'firebase/database'
import { useForm, object } from '@kaliber/forms'
import { optional } from '@kaliber/forms/validation'
import { useQueryString } from '@kaliber/use-query-string'

import { routeMap } from '/routeMap'
import { useTranslate, useLanguage } from '/machinery/I18n'
import { useFirebaseApp } from '/machinery/FirebaseAppProvider'
import { useReportError } from '/machinery/ReportError'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { requireTermsAndConditions, emailRequired, email } from '/machinery/customFormValidation'
import { useNormalizedJobFilterValues } from '/machinery/useNormalizedFilterValues'
import { useJobFiltersWithValues } from '/machinery/useFiltersWithValues'
import { storeHashedEmail } from '/machinery/tracking/storeHashedEmail'
import { useFormFieldRefs } from '/machinery/useFormFieldRefs'
import { moveFocusToError } from '/machinery/a11y/formHelpers'

import { FormFieldCheckbox, FormFieldCheckboxGroup, FormFieldInput, FormFieldSelect } from '/features/buildingBlocks/FormField'
import { Link } from '/features/buildingBlocks/Link'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { HeadingSm } from '/features/buildingBlocks/Heading'

import styles from './JobAlertForm.css'

export function JobAlertForm({ filters }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const firebaseApp = useFirebaseApp()
  const reportError = useReportError()
  const [queryString] = useQueryString()

  const normalizedValues = useNormalizedJobFilterValues(queryString)

  const filtersAndValues = useJobFiltersWithValues({
    filters,
    values: normalizedValues,
  })

  const filtersSorted = React.useMemo(
    () => {
      const entries = Object.entries(filtersAndValues)

      const customSort = ([idA, a], [idB, b]) => {
        if (idA === 'country') {
          return -1
        } else if (idB === 'country') {
          return 1
        }

        return a.display_order - b.display_order
      }

      return Object.fromEntries(entries.sort(customSort))
    },
    [filtersAndValues]
  )

  const { mutate: subscribeJobAlert, isPending, isError, isSuccess } = useMutation({
    mutationFn: handleSubscribeJobAlert,
    onError: reportError,
    onSuccess: ({ filters: { job_branch, country, remote_type, contract_type } }) => {
      pushToDataLayer({
        event: 'job_alert_subscribed',
        metadata: {
          jobalert: {
            expertise: job_branch?.length ? job_branch.join('||') : undefined,
            location: country ?? undefined,
            remote_type: remote_type?.length ? remote_type.join('||') : undefined,
            contract_type: contract_type?.length ? contract_type.join('||') : undefined,
          }
        }
      })
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  })

  const initialFilterValues = Object.fromEntries(
    Object.entries(filtersSorted).map(([id]) => id === 'country' ?
      [id, normalizedValues[id] || null] :
      [id, normalizedValues[id]]
    )
  )

  const { form, submit } = useForm({
    initialValues: {
      email: '',
      privacyStatement: false,
      filters: initialFilterValues
    },
    fields: {
      filters: object(
        Object.fromEntries(Object.entries(filtersSorted).map(([id]) => [id, optional]))
      ),
      email: [email, emailRequired(__`job-alert-form-field-email`)],
      privacyStatement: requireTermsAndConditions,
    },
    onSubmit: handleSubmit
  })

  const { fields: fieldRefs, setRef } = useFormFieldRefs({ formName: form.name })

  const { fields } = form

  return (
    <section
      data-x='job-alert-form'
      data-style-context='light'
      className={styles.component}
    >
      {isSuccess ?
        <SuccessMessage /> : (
          <form className={styles.form} noValidate onSubmit={submit}>
            {Object.entries(filtersSorted).map(([filterId, { options }]) => (
              filterId === 'country' ?
                <FormFieldSelect
                  ref={setRef(fields.filters.name)}
                  key={filterId}
                  label={__`filter-${filterId.replace('_', '-')}-title`}
                  field={fields.filters.fields[filterId]}
                  options={[
                    { value: null, label: __`filter-country-placeholder` },
                    ...options.map(option => ({ value: option.id, label: option.label }))
                  ]}
                /> :
                <FormFieldCheckboxGroup
                  key={filterId}
                  fieldsWrapper={InputsWrapper}
                  label={__`filter-${filterId.replace('_', '-')}-title`}
                  field={fields.filters.fields[filterId]}
                  {...{ filterId, options }}
                />
            ))}
            <FormFieldInput
              ref={setRef(fields.email.name)}
              field={fields.email}
              label={__`job-alert-form-field-email`}
              placeholder={__`job-alert-form-field-email`}
              type='email'
              required
              inputProps={{ maxLength: 255 }}
            />
            <FormFieldCheckbox
              ref={setRef(fields.privacyStatement.name)}
              field={fields.privacyStatement}
              ariaLabel={`${__`job-alert-form-field-privacyStatement`} ${__`privacy-statement`}`}
              label={
                <span aria-label={`${__`job-alert-form-field-privacyStatement`} ${__`privacy-statement`}`}>
                  {__`job-alert-form-field-privacyStatement`}
                  &nbsp;
                  <Link href='https://www.rabobank.com/privacy/downloads' dataX='link-to-privacy-statement'>{__`privacy-statement`}</Link>
                </span>
              }
              required
            />

            <div className={styles.recaptchaNotice}>
              {__`recaptcha-notice-1`}
              <ExternalLink
                href='https://policies.google.com/privacy'
                label={__`privacy-policy`}
                dataX='link-to-privacy-policy'
              />
              {__`recaptcha-notice-2`}
              <ExternalLink
                href='https://policies.google.com/terms'
                label={__`terms-of-service`}
                dataX='link-to-terms-of-service'
              />
              {__`recaptcha-notice-3`}
            </div>

            <div>
              <ButtonPrimary type='submit' dataX='submit-form' disabled={isPending} label={__`job-alert-form-button-submit`} />
            </div>
          </form>
        )}

      {isError && <ErrorMessage message={__`job-alert-form-general-error`} />}
    </section>
  )

  function handleSubmit({ invalid, value, error }) {
    if (invalid) {
      moveFocusToError({ refs: fieldRefs, error } )
      return
    }

    storeHashedEmail(value.email)
    subscribeJobAlert(value)
  }

  async function handleSubscribeJobAlert(formValues) {
    const { user: { uid } } = await signInAnonymously(getAuth(firebaseApp))

    const queueRef = ref(getDatabase(firebaseApp), 'services/job-alert-subscription-service/subscribing-queue')
    await push(queueRef, {
      formSubmitDate: serverTimestamp(),
      formValues,
      uid,
      language,
    })

    return formValues
  }
}

function ExternalLink({ href, label, dataX }) {
  const { __ } = useTranslate()

  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      aria-label={__({ x: label })`link-to-x-in-new-window`}
      data-x={dataX}
      {...{ href }}
    >
      {label}
    </a>
  )
}

function InputsWrapper({ children, ariaLabelledby = undefined }) {
  return (
    <div className={styles.componentInputsWrapper} role='group' aria-labelledby={ariaLabelledby}>
      { children }
    </div>
  )
}

function SuccessMessage() {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <div className={styles.componentSuccessMessage} role="region" aria-live="polite">
      <HeadingSm h={2} title={__`job-alert-form-success-title`} />
      <p>{__`job-alert-form-success-message`}</p>
      <Link dataX='link-to-jobs' href={routeMap.app.jobs({ language })}>{__`job-alert-form-back-link`}</Link>
    </div>
  )
}

function ErrorMessage({ message }) {
  return <div className={styles.componentErrorMessage}>{message}</div>
}
